import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  always,
  cond,
  equals,
  filter,
  map,
  mean,
  concat,
  prop,
  reduce,
  reverse,
} from 'ramda';
import { navigate } from 'gatsby';
import styled from '@emotion/styled';
import { useStaticQuery, graphql } from 'gatsby';
import { InView } from 'react-intersection-observer';
import HeroImage from '@greenberry/salal/components/HeroImage';
import Heading from '@greenberry/salal/components/Heading';
import Text from '@greenberry/salal/components/Text';
import Section from '@greenberry/salal/components/Section';
import Explanation from '@greenberry/salal/components/Explanation';
import formatValue from '@greenberry/salal/utils/formatValue';

import Button from '@greenberry/salal/components/Button';
import Card from '@greenberry/salal/components/Card';
import Icon from '@greenberry/salal/components/Icon';
import LineChart from '@greenberry/salal/components/Charts/Line';
import BarChart from '@greenberry/salal/components/Charts/Bar';
import GroupedBarChart from '@greenberry/salal/components/Charts/GroupedBar';
import MultiButton from '@greenberry/salal/components/MultiButton';
import theme from '@greenberry/salal/theme';
import Legend from '@greenberry/salal/components/Legend';
import CardToggle from '@greenberry/salal/components/CardToggle';
import { sectors } from '../../../../backend/constants';

import SlideIntoView from '../../../components/SlideIntoView';
import NationalLayout from '../../../components/NationalLayout';
import StudentCountTick from '../../../components/StudentCountTick';
import ThemeFooter from '../../../components/ThemeFooter';

import isMobile from '../../../utils/isMobile';
import notNil from '../../../utils/notNil';
import formatDataSource from '../../../utils/formatDataSource';
import parseTooltip from '../../../utils/parseTooltip';
import chartTitleGenerator from '../../../utils/chartTitleGenerator';

import {
  categoriesParticipationRate,
  categoriesStudentCount,
} from '../../../constants/studentCategories';
import {
  formatParticipationRate,
  formatStudentCount,
  formatAbsence,
} from './utils';
import getLatestYear from '../../../utils/getLatestYear';

const indicators = [
  {
    name: 'studentCount',
    Chart: BarChart,
    xKey: 'dataKey',
    mean: true,
    orientation:
      global.window &&
      global.window.matchMedia(`(max-width: ${theme.get('breakpoints.3')})`)
        .matches
        ? 'vertical'
        : 'horizontal',
    unit: 'leerlingen',
    width: 600,
    height: 450,
    hasFilters: true,
    sideNavData: {
      name: 'Aantal Leerlingen',
    },
  },
  {
    name: 'participationRate',
    Chart: GroupedBarChart,
    xKey: 'label',
    unit: 'percentage',
    height: 450,
    hasFilters: true,
    sideNavData: {
      name: 'Deelname percentage',
    },
  },
  {
    name: 'absence',
    Chart: GroupedBarChart,
    xKey: 'label',
    unit: false,
    height: 450,
    multiButton: [
      {
        value: 'absolute',
        label: 'Absoluut verzuim',
        visibleKeys: ['moreThanThreeAbsoluteAbsence', 'totalAbsoluteAbsence'],
      },
      {
        value: 'relative',
        label: 'Relatief verzuim',
        visibleKeys: [
          'longLastingRelativeAbsence',
          'moreThanThreeLongLastingRelativeAbsence',
        ],
      },
      { value: 'immunity', label: 'Vrijstelling', visibleKeys: ['immunity'] },
    ],
    sideNavData: {
      name: 'Verzuim',
    },
  },
];

const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  & > button {
    margin-right: ${theme.spacing('s')};
    &:last-of-type {
      margin-right: 0;
    }
  }
`;

const getCategoryFilters = cond([
  [equals('studentCount'), always(categoriesStudentCount)],
  [equals('participationRate'), always(categoriesParticipationRate)],
]);

const formatStudentCountLegend = indicator => {
  const _mean = mean(map(prop('value'), indicator));
  return [
    {
      label: `${formatValue(_mean, 'leerlingen')}`,
      subLabel: 'Gemiddeld per samenwerkingsverband in Nederland',
      color: theme.color('chart.3'),
    },
  ];
};
const formatParticipationRateLegend = () => [];
const formatAbsenceRateLegend = () => [];

const formatLegendItems = (indicator, name, selectedView) => {
  if (name === 'studentCount' && selectedView !== 'overview') return [];
  return cond([
    [equals('studentCount'), always(formatStudentCountLegend)],
    [equals('participationRate'), always(formatParticipationRateLegend)],
    [equals('absence'), always(formatAbsenceRateLegend)],
  ])(name)(indicator);
};

const renderFilters = ({
  name,
  sector,
  onFilterSelect = f => f,
  activeFilters = [],
}) => {
  const category = getCategoryFilters(name)[sector];

  return (
    <>
      <span>Filter op:</span>
      <FilterWrapper>
        {map(
          ({ label, key }) => (
            <Button
              small
              key={key}
              onClick={() => onFilterSelect(key)}
              color={
                activeFilters.length === 0 || activeFilters.includes(key)
                  ? 'primary'
                  : 'tertiary'
              }
            >
              {label}
            </Button>
          ),
          category
        )}
      </FilterWrapper>
    </>
  );
};

renderFilters.propTypes = {
  name: PropTypes.string.isRequired,
  sector: PropTypes.string.isRequired,
  onFilterSelect: PropTypes.func.isRequired,
  activeFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const Students = ({ pageContext: { copy, data, sector }, ...props }) => {
  const [selectedViews, setSelectedViews] = useState(
    reduce((acc, { name }) => ({ [name]: 'overview', ...acc }), {}, indicators)
  );

  const [activeMultiButtons, setActiveMultiButton] = useState(
    reduce(
      (acc, { name, multiButton }) => ({ [name]: multiButton[0], ...acc }),
      {},
      filter(({ multiButton }) => multiButton && multiButton.length, indicators)
    )
  );

  const filterStates = reduce(
    // eslint-disable-next-line
    (acc, { name }) => ({ [name]: useState([]), ...acc }),
    {},
    filter(prop('hasFilters'), indicators)
  );

  const dataSources = useStaticQuery(graphql`
    query {
      absence: datoCmsDataSource(indicator: { eq: "Verzuim" }) {
        source
        date
      }
      studentCount: datoCmsDataSource(indicator: { eq: "Aantal leerlingen" }) {
        source
        date
      }
      participationRate: datoCmsDataSource(
        indicator: { eq: "Deelname percentage" }
      ) {
        source
        date
      }
    }
  `);

  const _data = {
    participationRate: formatParticipationRate(
      data.participationRate,
      sector,
      filterStates.participationRate[0]
    ),
    studentCount: formatStudentCount(
      data.studentCount,
      sector,
      filterStates.studentCount[0]
    ),
    absence: formatAbsence(data.absence, activeMultiButtons.absence),
  };

  const setSelectedView = (indicator, view) => () => {
    const clone = Object.assign({}, selectedViews);
    clone[indicator] = view;
    setSelectedViews(clone);
  };

  const sideNavOptions = indicators.map(({ name, sideNavData }) => ({
    name: sideNavData.name,
    id: `${sideNavData.name}-${name}`,
  }));

  const [selectedIndicator, selectIndicator] = useState(sideNavOptions[0].name);
  const onSelectSwv = indicator => ({ dataKey }) => {
    const currentYear = getLatestYear(data[indicator]).filter(notNil);
    const subject = currentYear.find(({ name }) => name === dataKey);
    navigate(
      `/samenwerkingsverband/${sectors[subject.sector]}/${
        subject.slug
      }/leerlingen/`
    );
  };

  return (
    <NationalLayout
      title={copy.heroHeading}
      body={copy.heroBody}
      seoMetaTags={copy.seoMetaTags}
      heroImage={<HeroImage src="/images/DPO-leerlingen.png" opacity="1" />}
      sideNavOptions={sideNavOptions}
      selectedIndicator={selectedIndicator}
      {...props}
    >
      {indicators.map(
        (
          {
            name,
            Chart,
            xKey,
            height,
            width,
            unit,
            multiButton,
            hasFilters = false,
            ...chartProps
          },
          index
        ) => (
          <Section key={name} id={sideNavOptions[index].id}>
            <InView
              threshold={1}
              onChange={inView => {
                if (inView) {
                  selectIndicator(sideNavOptions[index].name);
                }
              }}
            >
              <Heading
                component="h1"
                size="xxl"
                appearance="secondary"
                id={name}
              >
                {parseTooltip(copy[`${name}Heading`])}
              </Heading>
            </InView>
            <Text size="m" appearance="base">
              {parseTooltip(copy[`${name}Body`])}
            </Text>
            <div></div>
            <CardToggle
              toggle={
                <>
                  <Button
                    onClick={setSelectedView(name, 'overview')}
                    color={
                      selectedViews[name] === 'overview'
                        ? 'primary'
                        : 'secondary'
                    }
                    styling="toggle"
                    type="button"
                  >
                    <Icon size="lg" type="chartBar" />
                    <span>Overzicht</span>
                  </Button>
                  <Button
                    color={
                      selectedViews[name] === 'history'
                        ? 'primary'
                        : 'secondary'
                    }
                    styling="toggle"
                    type="button"
                    onClick={setSelectedView(name, 'history')}
                  >
                    <Icon size="lg" type="chartLine" />
                    <span>Historie</span>
                  </Button>
                </>
              }
              filter={
                <>
                  {hasFilters &&
                    renderFilters({
                      name,
                      sector,
                      activeFilters: filterStates[name][0],
                      onFilterSelect: key => {
                        const [activeFilters, setActiveFilters] = filterStates[
                          name
                        ];

                        const update = activeFilters.includes(key)
                          ? filter(k => key !== k, activeFilters)
                          : concat(activeFilters, [key]);

                        setActiveFilters(update);
                      },
                    })}
                  {multiButton && multiButton.length && (
                    <>
                      <span>Filter op:</span>
                      <MultiButton
                        active={activeMultiButtons[name]}
                        color="primary"
                        horizontal
                        onChange={option =>
                          setActiveMultiButton({ [name]: option })
                        }
                        options={multiButton}
                        styling="solid"
                      />
                    </>
                  )}
                </>
              }
            />
            <SlideIntoView minHeight="250px">
              <Card>
                <Heading component="h2" size="xl" appearance="secondary">
                  {chartTitleGenerator(name)}
                </Heading>
                {selectedViews[name] === 'overview' ? (
                  <Chart
                    data={getLatestYear(_data[name]).filter(notNil)}
                    xKey={xKey}
                    yTick={name === 'studentCount' ? StudentCountTick : false}
                    matchKey={xKey}
                    height={height}
                    width={width}
                    unit={unit}
                    onBarSelect={
                      name === 'studentCount' ? onSelectSwv(name) : null
                    }
                    {...chartProps}
                  />
                ) : (
                  <LineChart
                    showTitle={isMobile()}
                    data={map(
                      year => ({
                        ...reduce(
                          (acc, curr) => {
                            if (name === 'studentCount' && notNil(curr)) {
                              acc.totaal = (acc.totaal || 0) + curr.value;
                            }
                            if (name !== 'studentCount' && notNil(curr)) {
                              acc[curr[xKey]] = curr.values[0].value;
                            }

                            return acc;
                          },
                          {},
                          _data[name][year]
                        ),
                        [xKey]: year.split('/')[0],
                      }),
                      reverse(Object.keys(_data[name]))
                    )}
                    height={height}
                    xKey={xKey}
                    yTick={name === 'studentCount' ? StudentCountTick : null}
                    unit={unit}
                    {...chartProps}
                  />
                )}
                <Legend
                  source={formatDataSource(dataSources[name])}
                  data={formatLegendItems(
                    getLatestYear(_data[name]).filter(notNil).filter(notNil),
                    name,
                    selectedViews[name]
                  )}
                />
              </Card>
            </SlideIntoView>
            {copy[`${name}Explanation`] && (
              <Explanation
                text={copy[`${name}Explanation`]}
                title="Toelichting"
              />
            )}
          </Section>
        )
      )}
      <ThemeFooter
        sector={sector}
        next={{
          label: 'Volgende hoofdstuk: Financiën',
          to: '/financien',
        }}
      />
    </NationalLayout>
  );
};

Students.propTypes = {
  pageContext: PropTypes.shape({
    copy: PropTypes.shape({
      heroHeading: PropTypes.string.isRequired,
      heroBody: PropTypes.string.isRequired,
      studentCountHeading: PropTypes.string.isRequired,
      studentCountBody: PropTypes.string.isRequired,
      studentCountExplanation: PropTypes.string.isRequired,
      participationRateHeading: PropTypes.string.isRequired,
      participationRateBody: PropTypes.string.isRequired,
      participationRateExplanation: PropTypes.string.isRequired,
      absenceHeading: PropTypes.string.isRequired,
      absenceBody: PropTypes.string.isRequired,
      absenceExplanation: PropTypes.string.isRequired,
      seoMetaTags: PropTypes.shape({}).isRequired,
    }).isRequired,
    sector: PropTypes.oneOf(['primair-onderwijs', 'voortgezet-onderwijs'])
      .isRequired,
    data: PropTypes.shape({
      participationRate: PropTypes.shape({}).isRequired,
      studentCount: PropTypes.shape({}).isRequired,
      absence: PropTypes.shape({}).isRequired,
    }).isRequired,
  }).isRequired,
};

export default Students;
